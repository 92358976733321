@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

@layer base {
    @import "public/assets/fontawesome/css/fontawesome.css";
    @import "public/assets/fontawesome/css/light.css";
    @import "public/assets/fontawesome/css/solid.css";
    @import "public/assets/fontawesome/css/custom-icons.css";
    @import "themes.css";
    @import "fonts.css";
    @import "garageList.css";
    @import "navigation.css";
    @import "listSiteText.css";
    @import "ratings.css";

    a {
        @apply text-primary underline font-bold;
    }

    .first {
        grid-area: first;
    }

    .second {
        grid-area: second;
    }

    .third {
        grid-area: third;
    }
    .fourth {
        grid-area: fourth;
    }

    @media screen and (min-width: 480px) {
        .container {
            max-width: 430px!important;
        }
        .garageItem {
            display: grid;
            grid-template-areas: 'first' 'second' 'third' 'fourth';
        }
        .map {
            height: 100vh;
        }
    }
    @media screen and (min-width: 768px) {
        .container {
            max-width: 738px!important;
        }
    }
    @media screen and (min-width: 976px) {
        .container {
            max-width: 930px!important;
        }
        .garageItem {
            display: grid;
            grid-template-areas: 'second second second' 'first third fourth';
            grid-template-columns: 30% 40% 30%
        }
    }
    @media screen and (min-width: 1440px) {
        .container {
            max-width: 1300px!important;
        }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .garage-description h3 {
        @apply text-lg mb-3;
    }

    .profileText ul{
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    .bookingOverviewBox {
        @media screen and (min-width: 728px) {
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.10);
        }
    }
    /* purgecss start ignore */
    .bookingGrid{
        @media screen and (min-width: 728px) {
            grid-template-areas:
        "one two"
        "three two";
            .one {
                grid-area: one;
            }
            .two {
                grid-area: two;
            }
            .three {
                grid-area: three;
            }
        }
    }
    /* purgecss end ignore */

}
